<template>
  <div class="instEditMain" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :isShow="isShow" :btn="btn" :strForm="instForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('instForm')" />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
      <el-col :md="4" class="vd_export2">
        <span style="margin-right: 2px">机构编号:</span>
        <el-input size="small" style="width: 150px" v-model="instForm.inst_no" disabled show-word-limit></el-input>
      </el-col>
    </div>
    <el-form :model="instForm" ref="instForm" label-width="120px" size="mini" :rules="rules" :disabled="isShow">
      <el-row>
        <el-col :md="8">
          <el-form-item label="机构类型" prop="inst_type">
            <el-select v-model="instForm.inst_type" clearable placeholder="请选择机构类型">
              <el-option v-for="item in instTypes" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="机构简称" prop="inst_abbr">
            <el-input v-model="instForm.inst_abbr" clearable maxlength="10" placeholder="请输入机构简称" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="所在城市" prop="inst_city">
            <el-select filterable v-model="instForm.inst_city" clearable>
              <el-option v-for="item in cityList" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="中文名称" prop="inst_cname">
            <el-input v-model="instForm.inst_cname" clearable maxlength="30" placeholder="请输入中文名称" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="英文名称" prop="inst_ename">
            <el-input v-model="instForm.inst_ename" clearable maxlength="50" placeholder="请输入英文名称" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="16">
          <el-form-item label="开户银行" prop="inst_bank">
            <el-input v-model="instForm.inst_bank" clearable maxlength="30" placeholder="请输入开户银行" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="银行账号" prop="inst_code">
            <el-input v-model="instForm.inst_code" clearable maxlength="30" placeholder="请输入银行账号" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="机构地址" prop="inst_addr">
            <el-input v-model="instForm.inst_addr" maxlength="255" placeholder="请输入机构地址" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="负责人" prop="inst_contact">
            <el-input v-model="instForm.inst_contact" clearable maxlength="30" placeholder="请输入负责人" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="负责人电话" prop="inst_cphone">
            <el-input v-model="instForm.inst_cphone" clearable maxlength="20" placeholder="请输入负责人电话" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="负责人邮箱" prop="inst_cmail">
            <el-input v-model="instForm.inst_cmail" clearable maxlength="30" placeholder="请输入负责人邮箱" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="开票人" prop="inst_biller">
            <el-input v-model="instForm.inst_biller" clearable maxlength="30" placeholder="请输入开票人" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="开票人电话" prop="inst_bphone">
            <el-input v-model="instForm.inst_bphone" clearable maxlength="20" placeholder="请输入开票人电话" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="开票人邮箱" prop="inst_bmail">
            <el-input v-model="instForm.inst_bmail" clearable maxlength="30" placeholder="请输入开票人电话" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="instForm.stff_name"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { instAPI } from '@api/modules/inst';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { optnAPI } from '@api/modules/optn';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'instEditMain',
  components: {
    editHeader,
    inputUser
  },
  data() {
    return {
      instForm: {
        inst_no: null,
        inst_type: null,
        inst_abbr: null,
        inst_city: null,
        inst_cname: null,
        inst_ename: null,
        inst_bank: null,
        inst_code: null,
        inst_addr: null,
        inst_contact: null,
        inst_cphone: null,
        inst_cmail: null,
        inst_biller: null,
        inst_bphone: null,
        inst_bmail: null
      },
      isShow: true,
      loadingFlag: false,
      btn: {},
      stffForm: {},
      cityList: [],
      instTypes: [],
      rules: {
        inst_type: [{ required: true, message: ' ', trigger: 'blur' }],
        inst_abbr: [{ required: true, message: ' ', trigger: 'blur' }],
        inst_city: [{ required: true, message: ' ', trigger: 'blur' }],
        inst_ename: [{ required: true, message: ' ', trigger: 'blur' }],
        inst_contact: [{ required: true, message: ' ', trigger: 'blur' }],
        inst_bank: [{ required: true, message: ' ', trigger: 'blur' }],
        inst_code: [{ required: true, message: ' ', trigger: 'blur' }]
      }
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getInstInfo();
      this.getOptnList();
    },
    getOptnList() {
      //获取机构类型
      get(optnAPI.getOptnByPermId, { perm_id: 10012 })
        .then(res => {
          if (res.data.code === 0) {
            this.instTypes = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
      //获取所在城市
      get(optnAPI.getOptnByPermId, { perm_id: 10011 })
        .then(res => {
          if (res.data.code === 0) {
            this.cityList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      if (!this.instForm.inst_cmail && !this.instForm.inst_cphone) return this.$message.warning('负责人电话或邮箱必须填写一项!');
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      console.log(this.instForm);
      post(instAPI.editInst, this.instForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getInstInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(instAPI.getInstById, { inst_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.instForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.instForm.stff_name;
            this.stffForm.dept_name = this.instForm.dept_name;
            this.stffForm.dept_team_name = this.instForm.dept_team_name;
            this.stffForm.user_id = this.instForm.user_id;
            this.stffForm.dept_id = this.instForm.dept_id;
            this.stffForm.stff_id = this.instForm.stff_id;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped>
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.el-row {
  margin-bottom: 5px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 120px;
}
</style>
